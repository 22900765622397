import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "../context/ThemeProvider";

export default function Header() {
  const navigate = useNavigate();

  const { theme, setTheme } = useContext(ThemeContext);

  const handleLogout = async () => {
    localStorage.clear();
    navigate("/login");
  };

  const handleRefresh = () => {
    window.location.reload();
  };


  return (
    <div className="header">
      <img
        onClick={handleRefresh}
        className="header-logo"
        src={theme ? "/images/almond-logo-dark.png" : "/images/almond_new_logo.png"}
        alt=""
      />
      <div className="mc-header-buttons-container">
        <div
          className="mc-theme-toggle-button-container"
          onClick={() => {
            setTheme(!theme);
          }}
        >
          {theme ? (
            <i class="fa-solid fa-sun"></i>
          ) : (
            <i class="fa-solid fa-moon"></i>
          )}
        </div>
        <button className="btn btn-logout" onClick={handleLogout}>
          <i class="fa-solid fa-arrow-right-from-bracket"></i>
          <span className="mc-logout-button-text">Logout</span>
        </button>
      </div>
    </div>
  );
}
