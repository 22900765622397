import React from "react";

const Footer = () => {
  return (
    <div>
      <footer class="footer bg-dark text-center text-white">
        <div
          className="text-center p-3  d-md-flex justify-content-between"

        >
          <div className="ps-sm-3 mb-md-0 mb-2">
            Copyright © {new Date().getFullYear()}:
            <a
              className="text-white"
              style={{ textDecoration: "none" }}
              href="https://almonds.ai/"
            >
              {" "}
              <u style={{ textDecoration: 'none', color: '#ea8423' }}>Almonds</u> All rights reserved.
            </a>
          </div>
          <div className="pe-sm-3">
            Made with &#9829; by{" "}
            <img
              src="/images/almond-logo-dark.png"
              alt=""
              width={100}
              style={{ marginLeft: "-10px" }}
            />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
